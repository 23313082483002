import moment, { type Moment } from 'moment';
import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';
import ChallengePeriodEnum from '@/entities/ChallengePeriodEnum';

class ChallengeHelper {
  static changeChallengeHash(id: string): string {
    if (id.startsWith('#w') || id.startsWith('#od') || id.startsWith('#t')) {
      return id;
    }

    return id.replace('#', '#f');
  }

  static getDaysForFinish(challenge: ChallengeResponseModel): number {
    const endDate = this.getChallengeEndDate(challenge);

    const currentDayStart = moment().startOf('day');
    const diff = endDate.diff(currentDayStart);

    const days = Math.floor(moment.duration(diff).asDays());

    return days < 0 ? 0 : days;
  }

  static isChallengeActive(challenge: ChallengeResponseModel): boolean {
    // TODO: убрать на бэк
    if (challenge.success || !challenge.approved || challenge.deleted || challenge.failed || challenge.archived) {
      return false;
    }

    const currentDate = moment();

    const subtractEndDate = this.getPeriod(challenge);
    let endDate = moment(this.getStartDate(challenge)).subtract(-subtractEndDate, 'd');

    const subtractAwardsDate = subtractEndDate + ChallengePeriodEnum.AWARDS_PERIOD;
    const awardsDate = moment(this.getStartDate(challenge)).subtract(-subtractAwardsDate, 'd');

    if (challenge.event && challenge.event.tournament) {
      const tournamentAwardsDate = moment(this.getTournamentAwardEndDate(challenge));

      if (tournamentAwardsDate.diff(currentDate, 'd') <= 0) {
        return false;
      }

      endDate = moment(challenge.event.tournament.endDate);
    } else if (awardsDate.diff(currentDate) <= 0) {
      return false;
    }

    if (endDate.diff(currentDate) > 0) {
      return true;
    }

    const reportsCount = challenge.reports?.length ?? 0;
    const numberOfReports = challenge.numberOfReports ?? 0;

    return reportsCount >= numberOfReports;
  }

  static getChallengeStartDate(challenge: ChallengeResponseModel): string {
    return moment(this.getStartDate(challenge)).format('DD.MM.YYYY');
  }

  static getChallengeEndDate(challenge: ChallengeResponseModel): moment.Moment {
    if (challenge.event?.tournament) {
      return moment(challenge.event.tournament.endDate);
    }

    return moment(this.getStartDate(challenge)).subtract(-this.getPeriod(challenge), 'd');
  }

  private static getPeriod(challenge?: ChallengeResponseModel) {
    return challenge?.event?.period ?? challenge?.period ?? 0;
  }

  static getAwardsStartDate(challenge: ChallengeResponseModel): string {
    if (challenge.event?.tournament) {
      return moment(challenge.event.tournament.endDate)
        .locale('ru')
        .format('DD MMMM');
    }

    return moment(this.getStartDate(challenge))
      .subtract(-this.getPeriod(challenge), 'd')
      .locale('ru')
      .format('DD MMMM');
  }

  static getAwardsEndDate(challenge: ChallengeResponseModel): string {
    const tournamentAwardEndDate = this.getTournamentAwardEndDate(challenge);

    if (tournamentAwardEndDate) {
      return tournamentAwardEndDate
        .locale('ru')
        .format('DD MMMM');
    }

    const awardsPeriod = this.getPeriod(challenge) + ChallengePeriodEnum.AWARDS_PERIOD;

    return moment(this.getStartDate(challenge))
      .subtract(-awardsPeriod, 'd')
      .locale('ru')
      .format('DD MMMM');
  }

  static getStartDate(challenge: ChallengeResponseModel): string | undefined | Date {
    const tournamentStartDate = challenge.event?.tournament?.startDate;
    const eventStartDate = challenge.event?.startDate;
    const { createdAt } = challenge;

    return tournamentStartDate ?? eventStartDate ?? createdAt;
  }

  static getTournamentAwardEndDate(challenge: ChallengeResponseModel): Moment | null {
    if (!challenge.event?.tournament?.endDate) {
      return null;
    }

    const tournamentEndDate = moment(challenge.event.tournament.endDate);
    return tournamentEndDate.add(ChallengePeriodEnum.AWARDS_PERIOD, 'd');
  }
}

export default ChallengeHelper;
