import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6c909d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-account-page" }
const _hoisted_2 = {
  key: 0,
  class: "preloader"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "user-account-page__header" }
const _hoisted_5 = { class: "user-account-page__info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChallengeForm = _resolveComponent("ChallengeForm")!
  const _component_PremiumWidget = _resolveComponent("PremiumWidget")!
  const _component_UserSummaryWidget = _resolveComponent("UserSummaryWidget")!
  const _component_RingInfoWidget = _resolveComponent("RingInfoWidget")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_ActiveChallengesWidget = _resolveComponent("ActiveChallengesWidget")!
  const _component_EventWidget = _resolveComponent("EventWidget")!
  const _component_FreelineStatisticsWidget = _resolveComponent("FreelineStatisticsWidget")!
  const _component_RatingClubWidget = _resolveComponent("RatingClubWidget")!
  const _component_FriendsWidget = _resolveComponent("FriendsWidget")!
  const _component_TreasureIsland = _resolveComponent("TreasureIsland")!
  const _component_a_collapse = _resolveComponent("a-collapse")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.state.challengeUser)
      ? (_openBlock(), _createBlock(_component_ChallengeForm, {
          key: 0,
          onClose: _ctx.handleChallengeUserClose
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.state.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Loading... "))
        : (_ctx.userInfo)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_PremiumWidget),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_UserSummaryWidget, { user: _ctx.userInfo }, null, 8, ["user"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_a_collapse, {
                  modelValue: _ctx.state.activeKey,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.activeKey) = $event)),
                  bordered: false,
                  "expand-icon-position": "end"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_collapse_panel, { header: _ctx.ringInfoTitle }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RingInfoWidget, { user: _ctx.userInfo }, null, 8, ["user"])
                      ]),
                      _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_a_collapse_panel, { header: "Активные цели-челленджи" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ActiveChallengesWidget, { user: _ctx.userInfo }, null, 8, ["user"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_collapse_panel, { header: "Зарегистрироваться на событие" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_EventWidget)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_collapse_panel, { header: "Статистика фрилайна" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_FreelineStatisticsWidget, {
                          user: _ctx.userInfo,
                          "archive-challenge-list": _ctx.archiveChallengeList
                        }, null, 8, ["user", "archive-challenge-list"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_collapse_panel, { header: "Чат для отчётов" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RatingClubWidget, { user: _ctx.userInfo }, null, 8, ["user"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_collapse_panel, { header: "Позвать друга" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_FriendsWidget, {
                          user: _ctx.userInfo,
                          onChallenge: _ctx.handleChallengeClick
                        }, null, 8, ["user", "onChallenge"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_collapse_panel, { header: "Остров сокровищ" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TreasureIsland, { user: _ctx.userInfo }, null, 8, ["user"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]))
          : _createCommentVNode("", true)
    ])
  ], 64))
}