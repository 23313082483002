<template>
  <a-modal
    v-model:open="state.show"
    :centered="true"
    :closable="false"
    title="Отзыв"
    class="review-modal"
    @cancel="handle"
  >
    <div
      class="review-modal__content"
    >
      <div
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
        >
          Выбрать событие
        </a-typography-text>
        <a-select
          :options="options"
          @change="handleSelectChange"
        />
      </div>
      <div
        v-show="isSelectedEvent && window === 'file'"
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
        >
          Прикрепить фото
        </a-typography-text>
        <a-upload
          :before-upload="handleBeforeUpload"
          style="width: 100%"
          @remove="handleRemove"
        >
          <a-button
            :disabled="!!state.review.file"
            style="width: 100%"
          >
            Загрузить
            <VerticalAlignBottomOutlined/>
          </a-button>
        </a-upload>
      </div>
      <div
        v-if="isSelectedEvent && window === 'about'"
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
        >
          Напиши пару слов о событии.
        </a-typography-text>
        <a-typography-text>
          Например, ты провел/а классную тренировку по плаванию или пробежал/а на лыжах. Сделал/а
          крутое фото или написал/а стихотворение. Любое действие, которое двинуло тебя вперед -
          подходит. ⬇️
        </a-typography-text>
        <a-textarea
          :maxlength="250"
          v-model:value="state.review.about"
        />
      </div>
      <div
        v-if="isSelectedEvent && window === 'result'"
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
        >
          Принято ✅
        </a-typography-text>
        <a-typography-text>
          Опиши свой результат, итог действия (подходит любой – медаль, место в рейтинге,
          расстояние, темп, количество страниц, шахматных партий, пр) Даже проигрыш подходит, ведь
          он делает тебя сильнее!
        </a-typography-text>
        <a-textarea
          v-model:value="state.review.result"
          :maxlength="250"
        />
      </div>
      <div
        v-if="isSelectedEvent && window === 'emotions'"
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
        >
          Идеально 😍
        </a-typography-text>
        <a-typography-text>
          Поделись, какие эмоции ты испытываешь, хочешь ли повторить?
        </a-typography-text>
        <a-textarea
          v-model:value="state.review.emotions"
          :maxlength="250"
        />
      </div>
      <div
        v-if="isSelectedEvent && window === 'team'"
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
        >
          Ура! Последний шаг перед отправкой: укажи название своей сборной
        </a-typography-text>
        <a-typography-text>
          (например: Бежим к солнцу, Вдарим по року, У Сережи в дзене, пр.)⬇️
        </a-typography-text>
        <a-input
          v-model:value="state.review.team"
        />
      </div>
      <div
        v-if="isSelectedEvent && window === 'submit'"
        class="review-modal__block"
      >
        <a-typography-text
          :strong="true"
          class="w-100"
          :align="'center'"
        >
          Отправить
        </a-typography-text>
      </div>
    </div>
    <template
      #footer
    >
      <div
        v-if="isSelectedEvent"
        :class="buttonsClassList"
      >
        <div>
          <a-button
            v-if="window === 'submit'"
            key="submit"
            type="primary"
            @click="handleSendReview"
          >
            Отправить
          </a-button>
          <a-button
            v-if="window !== 'file' && window !== 'submit'"
            key="submit"
            type="primary"
            @click="handlePreviousClick"
          >
            Предыдущий
          </a-button>
        </div>
        <div>
          <a-button
            v-if="window !== 'submit'"
            key="submit"
            type="primary"
            :disabled="!state.review[window]"
            @click="handleNextClick"
          >
            Следующий
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script
  setup
  lang="ts"
>
import {
  reactive,
  defineProps,
  defineEmits,
  withDefaults,
  onMounted,
  watch,
  computed,
} from 'vue';
import EventTemplateModel from '@/shared/Api/Model/RPC/Event/EventTemplateModel';
import {
  VerticalAlignBottomOutlined,
} from '@ant-design/icons-vue';
import TournamentModel from '@/entities/Tournament/TournamentModel';

interface State {
  show: boolean;
  review: {
    about: string;
    result: string;
    team: string;
    emotions: string;
    file: File | null;
    eventTemplate?: EventTemplateModel | null;
    tournament?: TournamentModel | null;
  };
  window: number;
}

interface Props {
  show: boolean;
  eventTemplateList: EventTemplateModel[];
  tournamentList: TournamentModel[];
}

const emit = defineEmits(['applyTournament', 'applyEvent', 'close']);

const state = reactive<State>({
  show: false,
  review: {
    about: '',
    result: '',
    team: '',
    emotions: '',
    file: null,
    eventTemplate: null,
  },
  window: 0,
});

const props = withDefaults(
  defineProps<Props>(),
  {
    show: false,
  },
);

const windows = computed(() => [
  'file',
  'about',
  'result',
  'emotions',
  ...(state.review.tournament ? [] : ['team']),
  'submit',
]);

function handleSendReview() {
  if (state.review.tournament) {
    emit('applyTournament', { ...state.review });
  } else {
    emit('applyEvent', { ...state.review });
  }

  state.review = {
    about: '',
    eventTemplate: null,
    tournament: null,
    file: null,
  };

  state.show = false;
}

const options = computed(() => {
  const tournamentMap = props.tournamentList
    .map((tournament) => ({
      value: `tournament_${tournament.id}`,
      label: tournament.title,
    }));

  const eventTemplateMap = props.eventTemplateList
    .map((eventTemplate) => ({
      value: `event_${eventTemplate.id}`,
      label: eventTemplate.name,
    }));

  return [
    ...tournamentMap,
    ...eventTemplateMap,
  ];
});

const isSelectedEvent = computed(
  () => !!state.review.eventTemplate || !!state.review.tournament,
);

function handleSelectChange(stringId: string): void {
  if (stringId.startsWith('tournament_')) {
    const id = Number(stringId.replace('tournament_', ''));

    const tournament = props
      .tournamentList
      .find((tournament) => tournament.id === id);

    if (!tournament) {
      return;
    }

    state.review = {
      ...state.review,
      tournament,
    };

    return;
  }

  const id = Number(stringId.replace('event_', ''));

  const eventTemplate = props
    .eventTemplateList
    .find((eventTemplate) => eventTemplate.id === id);

  if (!eventTemplate) {
    return;
  }

  state.review = {
    ...state.review,
    eventTemplate,
  };
}

function handleBeforeUpload(file: File) {
  state.review.file = file;

  return false;
}

function handleRemove() {
  state.review.file = null;
}

function handle() {
  emit('close');

  state.show = false;
}

onMounted(() => {
  state.show = props.show;
});

watch(() => props.show, (newValue: boolean) => {
  state.show = newValue;
});

function handlePreviousClick() {
  state.window = state.window - 1 < 0 ? 0 : state.window - 1;
}

function handleNextClick() {
  state.window = state.window > windows.value.length ? state.window : state.window + 1;
}

const window = computed(() => windows.value[state.window]);

const buttonsClassList = computed(() => [
  'review-modal__buttons',
  window.value === 'submit' ? 'review-modal__buttons_center' : null,
]);

</script>

<style
  scoped
  lang="scss"
>
.review-modal {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    &_center {
      justify-content: center;
    }
  }
}
</style>
