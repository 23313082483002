import LocalStorageKeysEnum from '@/app/Services/LocalStorage/Enum/LocalStorageKeysEnum';

import ChallengeCreateDataModel from '@/entities/Challenge/ChallengeCreateDataModel';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';

import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';
import TournamentModel from '@/entities/Tournament/TournamentModel';
import ChallengeServiceInterface from './Contract/ChallengeServiceInterface';

class ChallengeService implements ChallengeServiceInterface {
  private readonly serviceContainer: ServiceContainerInterface;

  constructor(
    serviceContainer: ServiceContainerInterface,
  ) {
    this.serviceContainer = serviceContainer;
  }

  async create(): Promise<void> {
    const {
      apiService,
      localStorageService,
    } = this.serviceContainer;

    const data = localStorageService.read<ChallengeCreateDataModel>(LocalStorageKeysEnum.CHALLENGE_SAVE_DATA);
    const tournamentSaveData = localStorageService.read<TournamentModel>(LocalStorageKeysEnum.TOURNAMENT_SAVE_DATA);

    if (data && data.userName) {
      await apiService.userInfoApi.update({ fullName: data.userName });

      try {
        let reportType = null;

        if (data.reportType && typeof data.reportType !== 'string') {
          reportType = data.reportType.name;
        }

        if (data.type === 'TOURNAMENT_CHALLENGE') {
          reportType = data.reportType ?? null;
        }

        const challenge: ChallengeResponseModel = {
          description: data.description,
          reason: data.reason,
          bet: data.bet ? +data.bet : null,
          reportType,
          numberOfReports: data.numberOfReports ? +data.numberOfReports : null,
          type: data.type ?? 'CHALLENGE',
        };

        if (data.event) {
          challenge.event = data.event;
        }

        if (tournamentSaveData && challenge.type === 'TOURNAMENT_CHALLENGE') {
          await apiService.rpc.tournament.register.call({
            tournament: tournamentSaveData,
            challenge,
          });
          localStorageService.remove(LocalStorageKeysEnum.TOURNAMENT_SAVE_DATA);
        } else {
          await apiService.challengesApi.create(challenge);
        }

        localStorageService.remove(LocalStorageKeysEnum.CHALLENGE_SAVE_DATA);
      } catch {
        throw new Error('Challenge Service. Create challenge error');
      }
    }
  }
}

export default ChallengeService;
