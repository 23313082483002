import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
  RouteRecordRaw,
} from 'vue-router';
import ServiceContainerInterface from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import HomePage from '@/pages/HomePage.vue';
import UserAccountPage from '@/pages/UserAccountPage.vue';
import ChallengePage from '@/pages/ChallengePage.vue';
import ChallengeConfirmPage from '@/pages/ChallengeConfirmPage.vue';
import LocalStorageKeysEnum from '@/app/Services/LocalStorage/Enum/LocalStorageKeysEnum';
import ChallengeCreateSuccessPage from '@/pages/ChallengeCreateSuccessPage.vue';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import InvitationCodeModel from '@/shared/Api/Model/RPC/InvitationCode/InvitationCodeModel';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'userLogin',
    path: '/user/login',
  } as RouteRecordRaw,
  {
    name: 'start',
    path: '/start',
    component: ChallengePage,
  } as RouteRecordRaw,
  {
    name: 'tournamentChallenge',
    path: '/tournament-challenge',
    component: ChallengePage,
  } as RouteRecordRaw,
  {
    name: 'eventChallenge',
    path: '/event-challenge',
    component: ChallengePage,
  } as RouteRecordRaw,
  {
    name: 'challenge',
    path: '/challenge',
    component: ChallengePage,
  } as RouteRecordRaw,
  {
    name: 'oneDayChallenge',
    path: '/one-day-challenge',
    component: ChallengePage,
  } as RouteRecordRaw,
  {
    name: 'challengeCreateSuccess',
    path: '/challenge-create-success',
    component: ChallengeCreateSuccessPage,
  } as RouteRecordRaw,
  {
    name: 'challengeConfirm',
    path: '/challenge-confirm',
    component: ChallengeConfirmPage,
  } as RouteRecordRaw,
  {
    name: 'adminLogin',
    path: '/login/admin',
  } as RouteRecordRaw,
  {
    name: 'botLogin',
    path: '/in/profile/:token',
  } as RouteRecordRaw,
  {
    name: 'userAccount',
    path: '/user/account',
    component: UserAccountPage,
    meta: {
      title: 'Профиль фрилайнера',
    },
  },
  {
    name: 'home',
    path: '/',
    component: HomePage,
  },
];

export const getRouter = (serviceContainer: ServiceContainerInterface): Router => {
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

  router.beforeEach(async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    const challengePages = [
      'home',
      'start',
      'challengeConfirm',
    ];
    const loginRoutes = [
      'adminLogin',
      'userLogin',
    ];
    const isLoginPage = loginRoutes.includes(to.name as string);
    const isBotLoginRoute = to.name as string === 'botLogin';
    const isUserLoginPage = to.name as string === 'userLogin';
    const token = to.query.code ?? to.params.token;

    if ((isLoginPage || isBotLoginRoute) && !token) {
      return next();
    }

    if (
      (
        isLoginPage
        || isBotLoginRoute
      )
      && serviceContainer.utilsService.typeCheck.isString(token)
    ) {
      let authResponse;

      if (isLoginPage) {
        authResponse = await serviceContainer.authorizationService
          .auth(token)
          .catch(() => null);
      } else {
        authResponse = await serviceContainer.authorizationService
          .authByCode(token)
          .catch(() => null);
      }

      if (!authResponse) {
        return next();
      }

      await serviceContainer.store.dispatch('updateUser').catch(() => null);

      if (isUserLoginPage) {
        const savedChallenge = serviceContainer.localStorageService
          .read(LocalStorageKeysEnum.CHALLENGE_SAVE_DATA);

        const invitationCode = serviceContainer.localStorageService
          .read<InvitationCodeModel>(LocalStorageKeysEnum.INVITATION_CODE);

        if (invitationCode?.club) {
          await serviceContainer?.apiService.rpc.club.addToUser.call(invitationCode.club);

          serviceContainer?.localStorageService.remove(LocalStorageKeysEnum.INVITATION_CODE);
        }

        if (savedChallenge) {
          return next({ name: 'challengeCreateSuccess' });
        }
      }

      if (isBotLoginRoute) {
        const user: UserInfoResponse = serviceContainer.store.getters.getUser;
        const isNewUser = user.challenges.length === 0
          && user.clubs.length === 0
          && user.tournaments.length === 0;

        if (isNewUser) {
          return next({ name: 'start' });
        }
      }

      return next({ name: 'userAccount' });
    }

    if (
      !serviceContainer.authorizationService.isAuthorised()
      && challengePages.includes(to.name as string)
    ) {
      return next();
    }

    if (!serviceContainer.authorizationService.isAuthorised()) {
      return next({ name: 'home' });
    }

    if (to.name === 'home') {
      await serviceContainer.store.dispatch('updateUser');

      return next({ name: 'userAccount' });
    }

    return next();
  });

  return router;
};
