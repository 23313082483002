import { Module } from 'vuex';
import EventTemplateModel from '@/app/Store/Model/EventTemplateModel';
import EventTemplateRPCModel from '@/shared/Api/Model/RPC/Event/EventTemplateModel';

export const eventTemplate: Module<EventTemplateModel, unknown> = {
  state: {
    eventTemplate: null,
  },

  getters: {
    getEventTemplate(state) {
      return state.eventTemplate;
    },
  },

  mutations: {
    setEventTemplate(state, payload) {
      state.eventTemplate = payload;
    },
  },

  actions: {
    addEventTemplate({ commit }, eventTemplate: EventTemplateRPCModel) {
      commit('setEventTemplate', eventTemplate);
    },

    deleteEventTemplate({ commit }) {
      commit('setEventTemplate', null);
    },
  },
};
