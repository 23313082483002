import EventListActualMethod from '@/shared/Api/Api/RPC/Event/ListActualMethod';
import ReportTypeListActualMethodInterface
  from '@/shared/Api/Contract/Api/RPC/ReportType/ListActualMethodInterface';
import ReportTypeListActualMethod from '@/shared/Api/Api/RPC/ReportType/ListActualMethod';
import ChallengeListActualMethodInterface
  from '@/shared/Api/Contract/Api/RPC/Challenge/ListActualMethodInterface';
import ChallengeListActualMethod from '@/shared/Api/Api/RPC/Challenge/ListActualMethod';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import ApplyMethodInterface
  from '@/shared/Api/Contract/Api/RPC/InvitationCode/ApplyMethodInterface';
import ApplyMethod from '@/shared/Api/Api/RPC/InvitationCode/ApplyMethod';
import RPCApiInterface from '@/shared/Api/Contract/Api/RPC/RPCApiInterface';
import AddToUserMethodInterface from '@/shared/Api/Contract/Api/RPC/Club/AddToUserMethodInterface';
import AddToUserMethod from '@/shared/Api/Api/RPC/Club/AddToUserMethod';
import EventMethodsInterface
  from '@/shared/Api/Contract/Api/RPC/Event/EventMethodsInterface';
import SendReviewMethod from '@/shared/Api/Api/RPC/Event/SendReviewMethod';
import TournamentSendReviewMethod from '@/shared/Api/Api/RPC/Tournament/SendReviewMethod';
import UpdateMethodInterface from '@/shared/Api/Contract/Api/RPC/Challenge/UpdateMethodInterface';
import UpdateMethod from '@/shared/Api/Api/RPC/Challenge/UpdateMethod';
import ListMethod from '@/shared/Api/Api/RPC/Tournament/ListMethod';
import ListCurrentMethod from '@/shared/Api/Api/RPC/Tournament/ListCurrentMethod';
import TournamentApiInterface
  from '@/shared/Api/Contract/Api/RPC/Tournament/TournamentApiInterface';
import ListAvailableForRegistrationMethod
  from '@/shared/Api/Api/RPC/Tournament/ListAvailableForRegistrationMethod';
import RegisterMethod from '@/shared/Api/Api/RPC/Tournament/RegisterMethod';
import StatisticsMethod from '@/shared/Api/Api/RPC/Tournament/StatisticsMethod';

class RPCApi implements RPCApiInterface {
  private _event?: EventMethodsInterface;

  private _tournament?: TournamentApiInterface;

  private _reportType?: {
    listActual: ReportTypeListActualMethodInterface;
  };

  private _challenge?: {
    listActual: ChallengeListActualMethodInterface;
    update: UpdateMethodInterface;
  };

  private _invitationCode?: {
    apply: ApplyMethodInterface;
  };

  private _club?: {
    addToUser: AddToUserMethodInterface;
  };

  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  get event(): EventMethodsInterface {
    if (!this._event) {
      this._event = {
        listActual: new EventListActualMethod(this.client),
        sendReview: new SendReviewMethod(this.client),
      };
    }

    return this._event;
  }

  get tournament(): TournamentApiInterface {
    if (!this._tournament) {
      this._tournament = {
        list: new ListMethod(this.client),
        listCurrent: new ListCurrentMethod(this.client),
        listAvailableForRegistration: new ListAvailableForRegistrationMethod(this.client),
        register: new RegisterMethod(this.client),
        sendReview: new TournamentSendReviewMethod(this.client),
        getStatistics: new StatisticsMethod(this.client),
      };
    }

    return this._tournament;
  }

  get reportType(): { listActual: ReportTypeListActualMethodInterface } {
    if (!this._reportType) {
      this._reportType = {
        listActual: new ReportTypeListActualMethod(this.client),
      };
    }

    return this._reportType;
  }

  get challenge(): { listActual: ChallengeListActualMethodInterface, update: UpdateMethodInterface } {
    if (!this._challenge) {
      this._challenge = {
        listActual: new ChallengeListActualMethod(this.client),
        update: new UpdateMethod(this.client),
      };
    }

    return this._challenge;
  }

  get invitationCode(): { apply: ApplyMethodInterface } {
    if (!this._invitationCode) {
      this._invitationCode = {
        apply: new ApplyMethod(this.client),
      };
    }

    return this._invitationCode;
  }

  get club(): { addToUser: AddToUserMethodInterface } {
    if (!this._club) {
      this._club = {
        addToUser: new AddToUserMethod(this.client),
      };
    }

    return this._club;
  }
}

export default RPCApi;
