import ListActualMethodInterface
  from '@/shared/Api/Contract/Api/RPC/Event/ListActualMethodInterface';
import AxiosClientServiceInterface
  from '@/app/Services/AxiosClientService/Contract/AxiosClientServiceInterface';
import EventTemplateModel from '@/shared/Api/Model/RPC/Event/EventTemplateModel';

class ListActualMethod implements ListActualMethodInterface {
  private readonly client: AxiosClientServiceInterface;

  constructor(
    client: AxiosClientServiceInterface,
  ) {
    this.client = client;
  }

  async call(): Promise<EventTemplateModel[]> {
    return this.client.instance.post<EventTemplateModel[]>('/v2/event/listActual')
      .then((response) => response.data);
  }
}

export default ListActualMethod;
