<template>
 <PromoCodeWidget
   v-if="state.isCodeTemplate"
   @submitCode="onSubmitCode"
   @rejectCode="onRejectCode"
 />
  <a-card
    v-else
    hoverable
    class="card"
  >
    <template #cover>
      <img
        v-if="activeOption.image"
        alt="example"
        :src="activeOption.image"
      />
      <div
        class="card__text"
      >
        <template
          v-for="(text, index) in activeOption.textLines"
          :key="`textLine${index}`"
        >
          <p>
            {{ text.text }}
          </p>
          <ul
            v-if="text.list"
            class="card__list"
          >
            <li
              v-for="(listItem, listIndex) in text.list"
              :key="`listItem${index}${listIndex}`"
            >
              {{ listItem }}
            </li>
          </ul>
        </template>
      </div>
      <div
        v-if="activeOption.field && activeOption.valueType === 'input'"
        class="card__input"
      >
        <a-input
          :maxLength="activeOption.maxLength"
          :value="state.values[activeOption.field]"
          :disabled="state.disabled[activeOption.field]"
          @input="handleInputChange($event, activeOption.field)"
        />
      </div>
      <div
        v-if="activeOption.field && activeOption.valueType === 'buttonGroup'"
        class="card__input"
      >
        <div
          v-if="activeOption.field === 'numberOfReports'"
          class="card__button-group card__button-group_number-of-reports"
        >
          <a-button
            v-for="index in getMaxNumberOfReportsValue()"
            :key="`number-of-reports-${index}`"
            :type="getIsActiveButton(`${index}`, activeOption.field)"
            :disabled="state.disabled[activeOption.field]"
            size="small"
            @click="handleButtonGroupClick(`${index}`, activeOption.field)"
          >
            {{ index}}
          </a-button>
        </div>
        <div
          v-else-if="activeOption.field === 'reportType'"
          class="card__button-group card__button-group_report-type"
        >
          <a-button
            v-for="(item, index) in state.list"
            :key="`number-of-reports-${index}`"
            :type="getIsActiveButton(item.value, activeOption.field)"
            :disabled="state.disabled[activeOption.field]"
            size="small"
            @click="handleButtonGroupClick(item.value, activeOption.field)"
          >
            {{ item.label }}
          </a-button>
        </div>
      </div>
      <div
        class="card__buttons"
      >
        <a-button
          v-if="!showApplyButton && activeOption.buttonText"
          type="primary"
          block
          :disabled="isForwardDisabled"
          @click="handleButtonClick"
        >
          {{ activeOption.buttonText }}
        </a-button>
        <a-button
          v-if="showApplyButton"
          type="primary"
          block
          :disabled="isForwardDisabled"
          @click="handleApplyButton"
        >
          Готово
        </a-button>
      </div>
    </template>
  </a-card>
</template>

<script
  lang="ts"
>
import {
  computed, defineComponent, inject, onMounted, reactive, ref,
} from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import image1 from '@/app/assets/img/image1.jpg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import image2 from '@/app/assets/img/image2.jpg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import image3 from '@/app/assets/img/image3.jpg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import image4 from '@/app/assets/img/image4.jpg';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import LocalStorageKeysEnum from '@/app/Services/LocalStorage/Enum/LocalStorageKeysEnum';
import { useRouter } from 'vue-router';
import EventTemplateModel from '@/shared/Api/Model/RPC/Event/EventTemplateModel';
import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';
import PromoCodeWidget from '@/widgets/PromoCodeWidget/PromoCodeWidget.vue';
import { notification } from 'ant-design-vue';
import ReportTypeModel from '@/shared/Api/Model/RPC/ReportType/ReportTypeModel';

type SavedChallengeLocalStorageModel = {
  description: string | null;
  reason: string | null;
  bet: string | null;
  reportType: string | null;
  numberOfReports: string | null;
  userName: string | null;
};

interface State {
  isCodeTemplate: boolean;
  values: SavedChallengeLocalStorageModel;
  disabled: {
    description: boolean;
    reason: boolean;
    bet: boolean;
    reportType: boolean;
    numberOfReports: boolean;
  };
  event: EventTemplateModel | null;
  list: Array<{ value: string, label: string }>;
  rawReportTypes: Array<ReportTypeModel>;
}

export default defineComponent({
  name: 'ChallengePage',

  components: {
    PromoCodeWidget,
  },

  setup() {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const router = useRouter();

    const state = reactive<State>({
      isCodeTemplate: true,
      values: {
        description: null,
        reason: null,
        bet: null,
        reportType: null,
        numberOfReports: null,
        userName: null,
      },
      disabled: {
        description: false,
        reason: false,
        bet: false,
        reportType: false,
        numberOfReports: false,
      },
      event: null,
      list: [],
      rawReportTypes: [],
    });

    interface StepInterface {
      image?: string,
      textLines: Array<{ text: string, mb?: string, list?: Array<string> }>,
      valueType?: 'buttonGroup' | 'input',
      maxLength?: number,
      field?: 'description' | 'reason' | 'bet' | 'reportType' | 'numberOfReports' | 'userName',
      buttonText?: string,
    }

    const isOneDayChallengePage = router.currentRoute.value.name === 'oneDayChallenge';
    const isTournamentChallengePage = router.currentRoute.value.name === 'tournamentChallenge';

    const options = computed<Array<StepInterface>>(() => {
      const about: Array<StepInterface> = [
        {
          image: image1,
          textLines: [
            {
              text: 'Привет!🔥 Поставить Цель-челлендж YouFC — это первый шаг к достижению твоих целей.',
              mb: '20px',
            },
            {
              text: 'Хочешь узнать, как это работает?',
            },
          ],
          buttonText: 'ХОЧУ',
        },
        {
          image: image2,
          textLines: [
            {
              // eslint-disable-next-line max-len
              text: 'Знаешь, какой крутой факт? Ты можешь выбрать цель, которая важна именно для тебя прямо сейчас! 🎯 Например, это может быть чтение статей на английском, отказ от кофе на 7 дней или что-то еще. Главное - цель должна быть измеримой в реальных действиях. Это инструмент для твоего успеха.',
              mb: '20px',
            },
          ],
          buttonText: 'ПОНЯТНО, ДАЛЬШЕ!',
        },
        {
          image: image3,
          textLines: [
            {
              // eslint-disable-next-line max-len
              text: '...Интересно, сколько раз в неделю ты хочешь выполнять свою цель? 🗓Здорово, что в Целях-челленджах YouFC можно выбрать, за сколько действий ты будешь выполнять свою цель в течение 14 дней. Например, это может быть 3 отчета или 10 отчетов. Это твоя уникальная цель, и ты решаешь какой путь выбрать!',
              mb: '20px',
            },
          ],
          buttonText: 'МНЕ ПОДХОДИТ, ДАЛЬШЕ!',
        },
        {
          image: image4,
          textLines: [
            {
              // eslint-disable-next-line max-len
              text: 'А знаешь, как участие в Целях-челленджах может быть весело и мотивирующе? 🌟 Как только ты будешь делать шаг к своей цели, просто поделись этим в своем чате поддержки. Это может быть текст, скрин или фото - лишь бы это было твоим реальным действием. И помни, за успешное выполнение цели ты получишь награду в YOU, а твой клуб поднимется в рейтинге. Давай начнем этот увлекательный путь вместе! 💪',
              mb: '20px',
            },
            {
              text: '👇👇👇👇👇👇👇👇👇👇',
            },
          ],
          buttonText: 'ИДУ ДОСТИГАТЬ ЦЕЛЬ',
        },
      ];

      const questions: Array<StepInterface> = [
        {
          textLines: [
            {
              text: 'Напиши измеримую цель на ближайшие 14 дней. Это срок этого челленджа. ',
              mb: '20px',
            },
            {
              // eslint-disable-next-line max-len
              text: 'На эти две недели ты можешь запланировать выполнение полезного действия от 3 до 14 раз. Это должны быть твои действия в реальной жизни.',
            },
            {
              text: 'Пиши цель с большой буквы, до 30 символов.',
            },
            {
              text: 'Примеры:',
              list: [
                '🔸Сделать 12 пробежек',
                '🔸Разобрать 10 шахматных партий',
                '🔸Убрать в квартире 5 раз',
                '🔸Каждый день медитировать 5 мин',
                '🔸Прочитать 10 страниц на англ',
                '🔸Не пить кофе 7 дней',
              ],
            },
            {
              text: 'Напиши свою цель👇🏼',
            },
          ],
          valueType: 'input',
          maxLength: 30,
          field: 'description',
          buttonText: 'ГОТОВО, ДАЛЬШЕ!',
        },
        {
          textLines: [
            {
              text: 'Выбери цифру, которая совпадает с количеством действий по достижению твоей цели.',
              mb: '20px',
            },
            {
              text: 'Примеры:',
              list: [
                '👉🏻Если твоя цель сделать 12 пробежек, выбери 12',
                '👉🏻Если твоя цель медитировать каждый день, выбери 14',
                '👉🏻Если цель убрать в квартире пять раз, выбери 5',
              ],
            },
          ],
          valueType: 'buttonGroup',
          field: 'numberOfReports',
          buttonText: state.disabled.numberOfReports ? 'ГОТОВО, ДАЛЬШЕ!' : undefined,
        },
        {
          textLines: [
            {
              // eslint-disable-next-line max-len
              text: 'Как ты будешь фиксировать ✅ свой результат?Выбери формат отчета, который ты будешь отправлять в чат своей группы поддержки',
              mb: '20px',
            },
            {
              text: 'Пример:',
              list: [
                '📶Если твоя цель 12 пробежек, выбери скрины и это будут скрины с бегового приложения.',
                '🔤Если твоя цель не пить кофе, выбери текст — это будет отчет по результатам дня',
                '🚻Если ты выбрал цель ежедневные прогулки, выбирай «отправлю фото» и фиксируй себя в процессе!',
              ],
            },
          ],
          valueType: 'buttonGroup',
          field: 'reportType',
          buttonText: state.disabled.reportType ? 'ГОТОВО, ДАЛЬШЕ!' : undefined,
        },
        {
          textLines: [
            {
              text: 'Напиши кратко своими словами, почему для тебя важно выполнить эту Цель-челлендж (до 255 символов)',
              mb: '20px',
            },
            {
              text: 'Пример:',
              list: [
                '📌Давно изучаю английский язык, говорить не с кем, решил читать для развития языка',
                '📌Сидячая работа, нужно больше гулять',
                '📌Кофе повышает давление, хочу бросить эту привычку',
              ],
            },
          ],
          valueType: 'input',
          maxLength: 255,
          field: 'reason',
          buttonText: 'ГОТОВО, ДАЛЬШЕ!',
        },
        {
          textLines: [
            {
              text: 'Напиши свое имя и фамилию или никнейм. Имя будет опубликовано на афише твоей Цели-челленджа.',
              mb: '20px',
            },
            {
              text: 'Пример:',
              list: [
                'Михаил Соловьев',
                'Масяня Вовановна',
              ],
            },
          ],
          valueType: 'input',
          field: 'userName',
          buttonText: 'ГОТОВО, ДАЛЬШЕ!',
        },
        {
          textLines: [
            {
              text: 'Хочешь огня?🔥',
              mb: '20px',
            },
            {
              // eslint-disable-next-line max-len
              text: 'Установи мотивирующую ставку в баллах YOU💰. Если ты не выполнишь Цель-челлендж, эта сумма спишется с твоего счета!',
            },
            {
              text: 'Если у тебя еще нет золота YOU или ты не хочешь делать ставку — введи 0.',
            },
          ],
          valueType: 'input',
          field: 'bet',
          buttonText: 'ГОТОВО, ДАЛЬШЕ!',
        },
      ];

      const isStartPage = router.currentRoute.value.name === 'start';
      const isRegisteredFromBot = serviceContainer.store.getters.getUser?.isRegisteredFromBot;

      return isStartPage && !isRegisteredFromBot ? [
        ...about,
        ...questions,
      ] : questions;
    });

    const activeOptionIndex = ref(0);

    const activeOption = computed(() => options.value[activeOptionIndex.value]);

    const isBackDisabled = computed(() => activeOptionIndex.value === 0);

    const isForwardDisabled = computed(() => {
      if (!activeOption.value.field) {
        return false;
      }

      return !state.values[activeOption.value.field];
    });

    const showApplyButton = computed(() => activeOptionIndex.value === (options.value.length - 1));

    function getIsActiveButton(
      value: string,
      field: 'description' | 'reason' | 'bet' | 'reportType' | 'numberOfReports' | 'userName',
    ): 'primary' | 'default' | 'text' {
      if (`${state.values[field]}` !== `${value}`) {
        return 'default';
      }

      if (field === 'userName') {
        return 'primary';
      }

      return state.disabled[field] ? 'text' : 'primary';
    }

    function handleButtonClick() {
      if (activeOptionIndex.value + 1 > options.value.length) {
        return;
      }

      activeOptionIndex.value += 1;

      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    function handleInputChange(
      event: Event,
      field: 'description' | 'reason' | 'bet' | 'reportType' | 'numberOfReports' | 'userName',
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const value = event.target?.value as string;

      state.values[field] = value === '' ? null : value;
    }

    function handleButtonGroupClick(
      value: string,
      field: 'description' | 'reason' | 'bet' | 'reportType' | 'numberOfReports' | 'userName',
    ) {
      state.values[field] = value;

      handleButtonClick();
    }

    function handleApplyButton() {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      const { values } = state;

      let type: 'CHALLENGE' | 'ONE_DAY_CHALLENGE' | 'TOURNAMENT_CHALLENGE' = 'CHALLENGE';

      if (isOneDayChallengePage) {
        type = 'ONE_DAY_CHALLENGE';
      } else if (isTournamentChallengePage) {
        type = 'TOURNAMENT_CHALLENGE';
      }

      const challenge: ChallengeResponseModel = {
        description: values.description,
        reason: values.reason,
        bet: values.bet ? Number(values.bet) : null,
        reportType: state.rawReportTypes.find((rawReport) => rawReport.name === values.reportType) ?? null,
        numberOfReports: values.numberOfReports ? Number(values.numberOfReports) : null,
        userName: values.userName,
        type,
      };

      if (state.event && !isTournamentChallengePage) {
        challenge.event = state.event;
      }

      if (serviceContainer?.store.getters.getInvitationCode) {
        const invitationCodeJson = JSON.stringify(serviceContainer?.store.getters.getInvitationCode);

        serviceContainer?.localStorageService.write(
          LocalStorageKeysEnum.INVITATION_CODE,
          invitationCodeJson,
        );

        serviceContainer.store.dispatch('deleteInvitationCode');
      }

      const json = JSON.stringify(challenge);

      serviceContainer?.localStorageService.write(LocalStorageKeysEnum.CHALLENGE_SAVE_DATA, json);

      router.push({ name: 'challengeConfirm' });
    }

    function initTournamentValues(eventTemplate: EventTemplateModel) {
      if (eventTemplate.description) {
        state.values.description = eventTemplate.description;
        state.disabled.description = true;
      }

      if (eventTemplate.numberOfReports) {
        state.values.numberOfReports = `${eventTemplate.numberOfReports}`;
        state.disabled.numberOfReports = true;
      }

      if (eventTemplate.reportType) {
        state.values.reportType = eventTemplate.reportType.name;
        state.disabled.reportType = true;
      }

      if (eventTemplate.reason) {
        state.values.reason = eventTemplate.reason;
        state.disabled.reason = true;
      }

      if (typeof eventTemplate.bet === 'number') {
        state.values.bet = `${eventTemplate.bet}`;
        state.disabled.bet = true;
      }
    }

    async function loadValues() {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      let savedValues = serviceContainer.localStorageService
        .read<SavedChallengeLocalStorageModel>(LocalStorageKeysEnum.CHALLENGE_SAVE_DATA);
      savedValues = savedValues ?? {} as SavedChallengeLocalStorageModel;

      if (serviceContainer.store.getters.getEventTemplate) {
        state.event = serviceContainer.store.getters.getEventTemplate;

        await serviceContainer.store.dispatch('deleteEventTemplate');
      }
      if (state.event) {
        initTournamentValues(state.event);
      } else if (savedValues && Object.keys(savedValues).length > 0) {
        state.values = savedValues;
      }
    }

    function getMaxNumberOfReportsValue() {
      let number = state.event?.period ?? 14;

      if (isOneDayChallengePage) {
        number = 1;
      }

      return number;
    }

    onMounted(async () => {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      if (router.currentRoute.value.name !== 'start') {
        state.isCodeTemplate = false;
      }

      if (isOneDayChallengePage) {
        state.disabled.numberOfReports = true;
        state.values.numberOfReports = '1';
      }

      state.rawReportTypes = await serviceContainer.apiService.rpc.reportType.listActual.call();

      state.list = state.rawReportTypes
        .map((reportType) => ({
          value: reportType.name,
          label: reportType.name,
        }));

      loadValues();
    });

    async function onSubmitCode(code: string) {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      try {
        const response = await serviceContainer.apiService.rpc.invitationCode.apply.call(code);

        if (response?.eventTemplate) {
          await serviceContainer.store.dispatch('addEventTemplate', response.eventTemplate);
          await serviceContainer.store.dispatch('addInvitationCode', response);

          state.event = serviceContainer.store.getters.getEventTemplate;

          await loadValues();

          await serviceContainer.store.dispatch('deleteEventTemplate');

          state.isCodeTemplate = false;
        }
      } catch (e) {
        notification.error({
          message: 'Улыбка!',
          description: 'Проверь правильность кода',
          duration: 30,
        });
      }
    }

    function onRejectCode() {
      state.isCodeTemplate = false;
    }

    return {
      activeOption,
      isBackDisabled,
      isForwardDisabled,
      getIsActiveButton,
      handleButtonClick,
      state,
      handleInputChange,
      handleButtonGroupClick,
      showApplyButton,
      handleApplyButton,
      getMaxNumberOfReportsValue,
      onRejectCode,
      onSubmitCode,
    };
  },
});
</script>

<style
  lang="scss"
>
.card {
  width: 300px;
  margin: 0 auto;

  &__text {
    padding: 10px;
  }

  &__buttons {
    display: flex !important;
    width: 100%;
    padding: 10px;
    gap: 8px;
  }

  &__list {
    padding: 0;
    list-style: none;
  }

  &__input {
    padding: 10px;
  }

  &__select {
    width: 100%;
  }

  &__button-group {
    display: flex;
    gap: 8px;

    &_number-of-reports {
      flex-wrap: wrap;
    }

    &_report-type {
      flex-direction: column;
    }
  }
}
</style>
